import React from "react"
import Page from "../components/page"
import Paragraph from "../components/paragraph"

const ContactParagraph = ({ parts }) => (
  <Paragraph version="secondary" orientation="vertical" textOrientation="left">
    {parts &&
      parts.map(part => (
        <div>
          <div className="paragraph-part-contact paragraph-part-contact-title">
            {part.title}
          </div>
          {part.texts &&
            part.texts.map(txt => (
              <div className="paragraph-part-contact paragraph-part-contact-text">
                {txt}
              </div>
            ))}
        </div>
      ))}
  </Paragraph>
)

export default function Contact({ labels, ...props }) {
  const contactParts = [
    {
      title: labels.telephoneNumber,
      texts: [
        <a href="tel:+385989843340">+385 98 9843340</a>,
        <a href="tel:+38549465047">+385 49 465047</a>
      ]
    },
    {
      title: labels.email,
      texts: [
        <a href="mailto:info@lunartech.hr">info@lunartech.hr</a>,
        <a href="mailto:prodaja@lunartech.hr">prodaja@lunartech.hr</a>
      ]
    }
  ]
  const officeParts = [
    {
      title: labels.office,
      texts: ["Vukovarska ulica 15", "HR-49282", "KONJŠČINA"]
    }
  ]

  return (
    <Page title={labels.contact} {...props} labels={labels}>
      <div className="paragraphs-page">
        <Paragraph
          title={labels.contactUppercased}
          version="primary"
          orientation="horizontal"
          textOrientation="left"
        />
        <div class="paragraphs-vertical">
          <ContactParagraph parts={contactParts} />
          <div className="paragraph-divider" />
          <ContactParagraph parts={officeParts} />
        </div>
      </div>
    </Page>
  )
}
